import styled from 'styled-components';

import theme from 'utils/theme';

import { getMarginBottom } from './helpers';
import { IAttributesLabelProps } from './types';

export const AttributesLabelSeparator = styled.span`
  &::before {
    content: '\u2219';
  }
`;

const stackedAttributesLabelStyling = `
  span {
    flex: 1 0 100%;

    & ${AttributesLabelSeparator} {
      display: none;
    }
  }
`;

export const AttributesLabel = styled.div<IAttributesLabelProps>`
  display: flex;
  flex-wrap: wrap;
  line-height: 1.3;
  font-size: 14px;
  color: ${theme.colors.grey};
  margin-top: 10px;
  margin-bottom: ${(props) => getMarginBottom(props.marginBottom)};
  ${(props) => (props.stack ? `${stackedAttributesLabelStyling}` : '')};
`;

import styled from 'styled-components';

import theme from 'utils/theme';

export const ColorSelectRow = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  marginBottom: -15,
  span: {
    marginRight: 20,
    marginBottom: 15,
  },
});

interface ColorSelectOptionsProps {
  hasLabel: boolean;
}

export const ColorSelectOptions = styled.div<ColorSelectOptionsProps>(
  (props) => ({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-10px',
    ...(props.hasLabel
      ? {
          marginBottom: 15,
        }
      : {}),
    '> *': {
      marginRight: 10,
    },
  }),
);

export const MoreColors = styled.div`
  color: ${theme.colors.grey};
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

interface BreakpointProps {
  media: string;
}

export const Breakpoint = styled.div<BreakpointProps>`
  display: none;

  ${(props) => `${props.media} {
    display: block;
  }`}
`;

import React from 'react';
import styled from 'styled-components';

import { urlFor } from 'utils/sanity';
import { SanityImageAsset, BreakpointValues } from 'utils/types';

interface MaskedIconElProps {
  src: string;
  size?: number | BreakpointValues;
}

const MaskedIconEl = styled.span<MaskedIconElProps>((props) => {
  const baseStyles: { [key: string]: any } = {
    display: 'inline-block',
    backgroundColor: 'currentColor',
    maskImage: `url(${props.src})`,
  };

  if (typeof props.size === 'object') {
    Object.entries(props.size).forEach(([key, value]) => {
      baseStyles[key] = {
        width: value,
        height: value,
        maskSize: `${value}px ${value}px`,
      };
    });
  } else {
    const size = props.size || 24;

    baseStyles.width = size;
    baseStyles.height = size;
    baseStyles.maskSize = `${size}px ${size}px`;
  }

  return baseStyles;
});

interface MaskedIconProps {
  asset: SanityImageAsset;
  size?: number | BreakpointValues;
  className?: string;
}

export default function MaskedIcon({
  asset,
  size,
  className,
}: MaskedIconProps) {
  const resolvedSrc = urlFor(asset).url();
  if (!resolvedSrc) {
    return null;
  }

  return <MaskedIconEl src={resolvedSrc} size={size} className={className} />;
}

import React from 'react';

import theme from 'utils/theme';

import { LoaderDiv, LoaderImg } from './styles';
import { ILoaderProps } from './types';

export default function Loader({
  zIndex = theme.zIndexes.loader,
  background = theme.colors.modalBackground,
  heightOverride = 'auto',
}: ILoaderProps) {
  const loaderDivProps = { zIndex, background, heightOverride };
  return (
    <LoaderDiv {...loaderDivProps}>
      <LoaderImg src="/loader.gif" alt="Loading..." />
    </LoaderDiv>
  );
}

import React from 'react';

import SiteLink from 'components/Link';

import { ColorDot, ColorDotWrapper } from './styles';
import { ColorSelectProps } from './types';

const ColorSelect = ({
  color,
  href,
  selected = false,
  size = 'large',
}: ColorSelectProps) =>
  href ? (
    <SiteLink href={href} scroll={false}>
      <ColorDotWrapper
        selected={selected}
        color={color}
        href={href}
        data-testid="colorSelect"
      >
        <ColorDot color={color} size={size} />
      </ColorDotWrapper>
    </SiteLink>
  ) : (
    <ColorDotWrapper
      selected={selected}
      color={color}
      href={href}
      data-testid="colorSelect"
    >
      <ColorDot color={color} size={size} />
    </ColorDotWrapper>
  );

export default ColorSelect;
